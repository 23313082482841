html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
button {
  outline: none;
}
:focus {
  outline: 1;
}
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}
* {
  box-sizing: border-box;
}
body a {
  color: #000000;
}
body a:link,
body a:visited,
body a:hover,
body a:active {
  cursor: pointer;
  text-decoration: none;
}
body p {
  margin: 0;
}
.rss-item {
  display: inline-block;
  background: #fff;
  height: 300px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rss-item .rss-item-header-tags {
  font-size: 12px;
  color: #828282;
}
.rss-item img {
  max-width: 100% !important;
}
.rss-item .rss-item-content {
  text-overflow: ellipsis;
  overflow: hidden;
}
body {
  background: #f0f0f0;
}
.ant-row {
  margin-bottom: 10px;
}
.rss-header {
  height: 50px;
  margin-bottom: 5px;
  width: 100%;
  line-height: 50px;
  z-index: 2;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
}
.rss-header .rss-menu-btn {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
}
.rss-header .header-title {
  color: #000000;
  font-weight: 400;
  margin-left: 10px;
}
